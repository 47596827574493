<template>
  <div id="content" data-elbglobals="page_type:wishlist">
    <AppBanner/>
    <TheHeader/>
    <TheWishlistTemplate/>
    <LazyComponent id="recent-items" :delay="1000">
      <TheRecentlyViewedItems class="pt-5"/>
    </LazyComponent>
    <LazyComponent id="footer" :delay="1500">
      <TheFooter/>
    </LazyComponent>
    <MultiTools id="fixed-tools" v-if="customerIsAdmin" />
  </div>
</template>
<script>
import TheWishlistTemplate from '@/components/wishlist/templates/TheWishlistTemplate'
import TheHeader from '@/components/basic/organisms/TheHeader'
import pageMixin from '@/components/basic/mixins/pageMixin'
import { TYPES } from '@/constants/AdminTool'
import { mapState } from 'pinia'
import { useCustomerStore } from '@/stores/customer'

const LazyComponent = () => import('@/components/basic/atoms/LazyComponent.vue')
const AppBanner = () => import('@/components/basic/molecules/AppBanner')
const TheFooter = () => import('@/components/basic/organisms/TheFooter')
const TheRecentlyViewedItems = () => import('@/components/basic/molecules/TheRecentlyViewedItems')
const MultiTools = () => import('@/components/basic/molecules/MultiTools')

export default {
  name: 'TheWishListPage',
  mixins: [pageMixin],
  components: {
    LazyComponent,
    TheWishlistTemplate,
    TheHeader,
    TheRecentlyViewedItems,
    TheFooter,
    AppBanner,
    MultiTools,
  },
  data () {
    return {
      adminTool: TYPES
    }
  },
  computed: {
    ...mapState(useCustomerStore, ['customerIsAdmin']),
    title () {
      return this.translate('new_cart_page_wishlist')
    },
    meta () {
      return {
        title: this.title,
        description: this.translate('fallback_meta_description'),
        robots: 'noindex, nofollow'
      }
    }
  },
  created () {
    this.afterEnter()
  }
}
</script>
