<template>
  <div id="content">
    <Header/>
    <div class="mt-3 mb-5 container c-mam-blue font-libre font-italic text-center">
      <h1>Playground</h1>
      <div class="pt-2">
        <p>
          This page is made for primarily frontend noobs in order to make them able to try out everything without ruining
          the overall performance in the app even if the changes got deployed.
        </p>
        <p>
          When you don't have a page to put new component that's the right place to come.
        </p>
      </div>
    </div>
    <TheFooter/>
  </div>
</template>

<script>
import pageMixin from '@/components/basic/mixins/pageMixin'

const TheFooter = () => import('@/components/basic/organisms/TheFooter')
const Header = () => import('@/components/basic/organisms/TheHeader')

export default {
  name: 'ThePlayground',
  mixins: [pageMixin],
  components: { Header, TheFooter },
  computed: {
    title () {
      return 'Playground'
    }
  }
}
</script>
