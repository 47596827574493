<template>
  <div id="content" itemscope itemtype="https://schema.org/manufacturer">
    <AppBanner/>
    <AlertBanner v-if="!visible" :text="translate('alert_message_preview_mode')"/>
    <TheHeader/>
    <transition name="fade">
      <main id="page-template" class="position-relative" data-elbglobals="page_type:manufacturer"
            :data-elbcontext="elbContext">
        <img class="bg-image d-none" :class="{ 'd-md-block' : calculated.bgImage }" :src="calculated.bgImage"
             alt="manufacturer background" :style="bgHeight"/>
        <TheIntroSection class="container px-0 pt-2" ref="intro" @loaded="adjustBg"/>
        <div :loading="loading" :class="{'container': loading}">
          <div id="manufacturer-products" class="headline-container text-center py-2">
            <h2 v-html="translate('products_by_manufaturer', manufacturer.name)"/>
          </div>
          <LazyComponent id="manufacturer-filter" :delay="1000">
            <TheFilter v-if="getProducts.length" class="px-md-0"/>
            <TheNoProducts v-else/>
          </LazyComponent>
          <div>
            <div class="container px-0 pt-2">
              <div id="manufacturer-description-section"
                   class="w-100 headline-container py-5 text-center">
                <h2 v-html="translate('manufacturer_more_about_winery')"/>
              </div>
              <LazyComponent id="manufacturer-description" :delay="1500">
                <TheDescriptionSection/>
              </LazyComponent>
            </div>
          </div>
        </div>
        <MultiTools id="fixed-tools" v-if="customerIsAdmin && adminTool">
          <Tool :type="adminTool.EDIT_CATEGORY" :id="category.code"/>
          <Tool :type="adminTool.EDIT_MANUFACTURER" :id="$route.params.id"/>
        </MultiTools>
      </main>
    </transition>
    <LazyComponent id="footer" :delay="1500">
      <Footer/>
    </LazyComponent>
  </div>
</template>

<script>
import pageMixin from '@/components/basic/mixins/pageMixin'
import TheHeader from '@/components/basic/organisms/TheHeader.vue'
import TheIntroSection from '@/components/manufacturer/molecules/TheIntroSection.vue'
import { useManufacturerStore } from '@/stores/manufacturer'
import { mapActions, mapState } from 'pinia'
import { useSearchConfig } from '@/stores/searchConfig'
import { useCustomerStore } from '@/stores/customer'

const TheNoProducts = () => import('@/components/manufacturer/atoms/TheNoProducts.vue')
const LazyComponent = () => import('@/components/basic/atoms/LazyComponent.vue')
const AppBanner = () => import('@/components/basic/molecules/AppBanner.vue')
const AlertBanner = () => import('@/components/basic/atoms/AlertBanner.vue')
const TheDescriptionSection = () => import('@/components/manufacturer/molecules/TheDescriptionSection.vue')
const TheFilter = () => import('@/components/category/organisms/TheFilter.vue')
const MultiTools = () => import('@/components/basic/molecules/MultiTools.vue')
const Tool = () => import('@/components/basic/atoms/Tool.vue')
const Footer = () => import('@/components/basic/organisms/TheFooter.vue')

export default {
  name: 'TheManufacturerPage',
  mixins: [pageMixin],
  components: {
    TheNoProducts,
    Tool,
    MultiTools,
    TheHeader,
    TheIntroSection,
    AppBanner,
    AlertBanner,
    LazyComponent,
    TheDescriptionSection,
    TheFilter,
    Footer
  },
  data() {
    return {
      adminTool: null,
      bgHeight: ''
    }
  },
  computed: {
    ...mapState(useSearchConfig, ['category', 'getProducts', 'getConfig', 'elbwalkerContext']),
    ...mapState(useManufacturerStore, ['manufacturer', 'calculated', 'loading']),
    ...mapState(useCustomerStore, ['customerIsAdmin']),
    visible() {
      return this.manufacturer.status !== 1
    },
    title() {
      return this.manufacturer.meta_title
    },
    description() {
      return this.manufacturer?.meta_description || this.translate('fallback_meta_description')
    },
    robots () {
      if (!this.visible) return 'noindex, nofollow'

      return this.manufacturer.robots > 0 ?  'noindex, nofollow' : 'index, follow'
    },
    meta() {
      return {
        title: this.title,
        description: this.description,
        robots: this.robots
      }
    },
    elbContext() {
      const context = this.elbwalkerContext
      context.push(`manufacturer:${this.manufacturer.name}`)
      return context.join(';')
    }
  },
  methods: {
    ...mapActions(useSearchConfig, ['fetchManufacturerProducts']),
    ...mapActions(useManufacturerStore, ['fetchManufacturer']),
    adjustBg() {
      this.bgHeight = `height: ${this.$refs.intro.$el.clientHeight - 30}px; max-height: 600px;`
    },
    setup(id) {
      this.fetchManufacturer(id)
      this.fetchManufacturerProducts(id)
    }
  },
  async created() {
    const { TYPES } = await import('@/constants/AdminTool')
    this.adminTool = TYPES
  },
  beforeRouteEnter({ params: { id } }, _, next) {
    next((vm) => {
      vm.setup(id)
    })
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .headline-container h2 {
    font-size: $font-size-xxxl;
    font-weight: normal;
    display: inline-block;
    padding: 10px 30px;
    position: relative;
    letter-spacing: 1px;
  }

  @media screen and (min-width: 768px) {
    .headline-container h2:before {
      right: 100%;
    }
    .headline-container h2:after {
      left: 100%;
    }

    .headline-container h2:before, .headline-container h2:after {
      background: $beige;
      content: "";
      display: block;
      height: 2px;
      position: absolute;
      top: 50%;
      width: 20vw;
    }
  }
}

.bg-image {
  transition: max-height $transition-duration-xxxxl;
  min-height: 390px;
  max-height: 0; // transition to 600px
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#manufacturer-products {
  margin-block: 10px;
  @media screen and (min-width: 768px) {
    margin-block: 50px;
  }
}
</style>
