<template>
  <div id="content" itemscope itemtype="https://schema.org/Product"
       data-elbglobals="page_type:product_detail">
    <AppBanner/>
    <AlertBanner v-if="!calculated.isVisible" :text="translate('alert_message_preview_mode')"/>
    <TheHeader/>
    <main id="page-template">
        <ThePdpTemplate/>
    </main>
    <LazyComponent id="footer" :delay="1500">
      <TheFooter/>
    </LazyComponent>
  </div>
</template>

<script>
import pageMixin from '@/components/basic/mixins/pageMixin'
import TheHeader from '@/components/basic/organisms/TheHeader'
import { mapActions, mapState } from 'pinia'
import { usePdpStore } from '@/stores/pdp'
import ThePdpTemplate from '@/components/pdp/templates/ThePdpTemplate.vue'

const LazyComponent = () => import('@/components/basic/atoms/LazyComponent.vue')
const AppBanner = () => import('@/components/basic/molecules/AppBanner')
const AlertBanner = () => import('@/components/basic/atoms/AlertBanner')
const TheFooter = () => import('@/components/basic/organisms/TheFooter')

const OPEN_MOBILE_APP_PARAM = 'vic_app'

export default {
  name: 'TheProductDetailPage',
  mixins: [pageMixin],
  components: {
    LazyComponent,
    AppBanner,
    AlertBanner,
    TheHeader,
    ThePdpTemplate,
    TheFooter
  },
  computed: {
    ...mapState(usePdpStore, {
      product: 'getApiProduct',
      productUpdating: 'isUpdating',
      calculated: 'getCalculated'
    }),
    metaOnCreate() {
      return false
    },
    title() {
      const manufacturer = this.product.general?.manufacturer_name ?? ''
      const productName = this.product.general?.name ?? ''

      return `${manufacturer} ${productName}`.trim()
    },
    description() {
      return this.product.general?.name
        ? this.product.description?.meta_description
        : this.translate('fallback_meta_description')
    },
    canonical() {
      return this.product.canonical ? [{ href: this.product.canonical }] : []
    },
    meta() {
      return {
        title: this.title,
        canonical: this.canonical,
        alternate: this.product.alternates ?? [],
        description: this.description,
        robots: this.product.general.robots
      }
    }
  },
  methods: {
    ...mapActions(usePdpStore, ['refreshProduct']),
    async routeChangeHandler(productId, campaignId) {
      await this.refreshProduct({ productId, campaignId })
      void this.productChangeHandler()
      void this.openMobileAppHandler({ productId, campaignId })
    },
    trackViewItem() {
      setTimeout(async () => {
        const [{ default: GA4Controller }, { default: AnalyticsItemFactory }] = await Promise.all([
          await import('@/services/analytics/GA4Controller'),
          await import('@/modules/tag_manager/AnalyticsItemFactory')
        ])
        void GA4Controller.pushViewItemEvent({
          item: AnalyticsItemFactory.getAnalyticsItemFromApiProduct(this.product)
        })
        void GA4Controller.pushChangePageEvent()
      }, 0)
    },
    async openMobileAppHandler ({ productId, campaignId }) {
      const { default: NavigationHelper } = await import('@/services/helpers/NavigationHelper')
      if (this.$router.currentRoute.query[OPEN_MOBILE_APP_PARAM] === 'true') {
        NavigationHelper.openMobileAppPdp({ productId, campaignId })
      }
    },
    async productChangeHandler() {
      this.trackViewItem()
      const [{ default: DomHelper }, { useLazyStore }] = await Promise.all(([
        await import('@/services/helpers/DomHelper'),
        await import('@/stores/lazyStore')
      ]))
      if (this.$route.hash) {
        const lazyStore = useLazyStore()
        await lazyStore.scroll({ selector: this.$route.hash })
        return
      }
      void DomHelper.scrollIntoView('content')
    }
  },
  beforeRouteEnter({ params: { pId, cId } }, _, next) {
    next((vm) => {
      vm.routeChangeHandler(pId, cId)
    })
  },
  beforeRouteUpdate({ params: { pId, cId } }, _, next) {
    this.routeChangeHandler(pId, cId)
    next()
  }
}
</script>
<style lang="scss" scoped>
.fade-enter-active {
  transition: opacity 1s $transition-timing-cb-vicampo .5s
}

.fade-leave-active {
  transition: opacity .5s $transition-timing-cb-vicampo
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

#main-footer {
  margin-bottom: 120px;
  // no breakpoint for this
  @media screen and (min-width: 329px) {
    margin-bottom: 100px;
  }

  @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
    margin-bottom: 0;
  }
}
</style>
