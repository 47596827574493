<template>
  <transition name="fade">
    <div v-show="readyToRender" id="content">
      <Header/>
      <TheWrapper/>
      <Footer/>
    </div>
  </transition>
</template>

<script>
import TheWrapper from '@/components/error/templates/TheErrorTemplate'
import pageMixin from '@/components/basic/mixins/pageMixin'

const Header = () => import('@/components/basic/organisms/TheHeader')
const Footer = () => import('@/components/basic/organisms/TheFooter')

export default {
  name: 'TheErrorPage',
  components: { TheWrapper, Header, Footer },
  mixins: [pageMixin],
  computed: {
    title () {
      return this.translate('fe_app_error_meta')
    },
    meta () {
      return {
        title: this.title,
        robots: 'noindex, nofollow'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.fade-enter-active {
  transition: opacity 1s $transition-timing-cb-vicampo .5s
}

.fade-leave-active {
  transition: opacity .5s $transition-timing-cb-vicampo
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
