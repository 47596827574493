<template>
  <div id="content">
    <Header/>
    <main id="page-template">
      <div class="position-relative">
        <div data-promotion-id="homepage_top_teaser_overall">
          <CmsBlock identifier="fe-app_t1_alternate_section"/>
        </div>
        <CmsBlock identifier="homepage_top_categories_2023_round"/>
        <CmsDelayedSection timeout=1000>
          <div v-if="hasRecommendations">
            <CmsBlock identifier="fe-app-homepage_bundles_tp_sort"/>
          </div>
          <div v-else>
            <CmsBlock identifier="fe-app-homepage_bundles"/>
          </div>
          <!-- RECOMMENDATIONS SECTION -->
          <div v-show="hasRecommendations">
            <CmsBlock identifier="fe-app_homepage_salutation"/>
            <CmsBlock identifier="fe-app_homepage_followups"/>
          </div>

          <div v-if="hasRecommendations">
            <CmsBlock identifier="fe-app_tabsection_recommendations"/>
          </div>
          <div v-else>
            <CmsBlock identifier="fe-app_tabtest_beliebte_rebsorten"/>
          </div>
        </CmsDelayedSection>
        <CmsDelayedSection timeout=1250>
          <CmsBlock identifier="homepage_section_premium_weine"/>
        </CmsDelayedSection>
        <CmsDelayedSection timeout=1500>
          <div data-promotion-id="homepage_bestseller_overall" class="mt-5">
            <CmsBlock identifier="fe_app_bestseller"/>
            <div class="container" style="">
              <div data-promotion-id="homepage_bestseller_product_slider">
                <CmsProductList category=bestseller-homepage type=products template=slider sort=deals_priority limit=9 item-list-id=slider_bestseller />
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-8 offset-md-2">
                <VicampoLink href="https://www.vicampo.de/weine-cat-c1?supplier=VICAMPO"
                             class="btn btn-primary btn-lg btn-block util" style="margin-bottom: 20px;margin-top:20px;" adjust-url
                             data-promotion-id="homepage_button_alleweine"
                             onClick="window.dataLayer.push({ event: 'eventTracking', eventCategory: 'homepage', eventAction: 'click', eventLabel: 'alleweine_btn', nonInteraction: false});">
                  Alle Weine entdecken <span class="glyphicon glyphicon-menu-right small" aria-hidden="true"></span></VicampoLink>
              </div>
            </div>
          </div>
          <CmsBlock identifier="fe-app-homepage_breaker_benefits_v2"/>
        </CmsDelayedSection>
        <CmsDelayedSection timeout=1500>
          <CmsBlock identifier="fe-app_homepage_three_column_teaser"/>
          <CmsBlock identifier="feapp_teaser_xxl_pakete_homepage"/>
        </CmsDelayedSection>
        <CmsDelayedSection timeout=1500>
          <div class="container">
            <div>
              <CustomerTestimonials/>
            </div>
          </div>
          <CmsBlock identifier="fe-app-breaker_partner_redesign_2019"/>
          <div class="container">
            <div>
              <WinemakerTestimonials/>
            </div>
          </div>
        </CmsDelayedSection>
        <CmsDelayedSection timeout=2000>
          <NewsletterBreaker/>
        </CmsDelayedSection>
      </div>
    </main>
    <TheFooter/>
  </div>
</template>

<script>
import pageMixin from '@/components/basic/mixins/pageMixin'
import { mapState } from 'pinia'
import { useCustomerStore } from '@/stores/customer'
const WinemakerTestimonials = () => import('@/components/cms/organisms/WinemakerTestimonials.vue')
const CustomerTestimonials = () => import('@/components/cms/organisms/CustomerTestimonials.vue')
const CmsBlock = () => import('@/components/basic/atoms/CmsBlock.vue')
const VicampoLink = () => import('@/components/basic/atoms/VicampoLink.vue')
const NewsletterBreaker = () => import('@/components/cms/molecules/NewsletterBreaker.vue')
const CmsDelayedSection = () => import('@/components/cms/organisms/CmsDelayedSection.vue')
const CmsProductList = () => import('@/components/cms/organisms/CmsProductList.vue')
const TheFooter = () => import('@/components/basic/organisms/TheFooter')
const Header = () => import('@/components/basic/organisms/TheHeader')

export default {
  name: 'TheHomePage',
  mixins: [pageMixin],
  components: { CustomerTestimonials, WinemakerTestimonials, CmsProductList, CmsDelayedSection, NewsletterBreaker, VicampoLink, CmsBlock, Header, TheFooter },
  computed: {
    ...mapState(useCustomerStore, ['customerProfile']),
    hasRecommendations () {
      return false
    },
    title () {
      return 'Home page test'
    },
    customerIsLoggedIn () {
      const { customer_id: customerId, email } = this.customerProfile
      return customerId && email
    },
    meta () {
      return {
        title: this.title,
        description: this.translate('fallback_meta_description'),
        robots: 'noindex, nofollow'
      }
    }
  }
}
</script>
<style scoped lang="scss">
.gradient_shine .text {
  font-weight: 600;
}
.gradient_shine .text p {
  margin: 4px 0 0;
}
.d-homepageonly {
  display: block !important;
}

#main-footer .footer-footnote {
  padding: 10px;
}
#recommendations-headline, #recommendations {
  background: #f8f6f3;
}
#recommendations {
  padding-bottom: 20px;
  margin-bottom: 30px;
}
#recommendations-headline {
  margin-top: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
}
#recommendations-headline .headline-container {
  margin-top: 0px;
}
#page-content .container {
  position: relative;
}
.page-layout-blank {
  background-color: #f0ede6;
}
.headline-container h1:before, .headline-container h1:after, .headline-container h2:before, .headline-container h2:after {
  height: 1px !important;
}
@media only screen and (max-width : 768px) {
  .change-position-mobile {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    display: flex;
  }
}
.dropdown-menu ul li:hover a {
  background: none !important;
}
</style>
