<template>
  <div id="content">
    <Header/>
    <div class="wrapper d-flex flex-column justify-content-around align-items-center">
      <h1>[ Complete pages ]</h1>
      <div class="container d-flex justify-content-center align-items-center py-2">
        <RouterLink :to="pdpUrl"><Button class="btn-primary flex-grow-1 mx-1" title="PDP"></Button></RouterLink>
        <RouterLink to="/checkout/cart"><Button class="btn-primary flex-grow-1 mx-1" title="Cart"></Button></RouterLink>
        <RouterLink to="/error"><Button class="btn-primary flex-grow-1 mx-1" title="Error"></Button></RouterLink>
        <RouterLink :to="categoryUrl"><Button class="btn-primary flex-grow-1 mx-1" title="Category"></Button></RouterLink>
        <RouterLink :to="cmsUrl"><Button class="btn-primary flex-grow-1 mx-1" title="Cms"></Button></RouterLink>
      </div>
      <h1>[ Pages in progress ]</h1>
      <div class="container d-flex justify-content-center align-items-center py-2">
        <RouterLink :to="opcUrl"><Button class="btn-primary flex-grow-1 mx-1" title="OPC"></Button></RouterLink>
      </div>
      <div class="container d-flex justify-content-center align-items-center py-2">
        <RouterLink to="/playground"><Button class="btn-primary flex-grow-1 mx-1" title="Playground"></Button></RouterLink>
      </div>
    </div>
    <TheFooter/>
  </div>
</template>

<script>
import pageMixin from '@/components/basic/mixins/pageMixin'

const Header = () => import('@/components/basic/organisms/TheHeader')
const TheFooter = () => import(/* webpackPrefetch: true */ '@/components/basic/organisms/TheFooter')
const Button = () => import('@/components/basic/atoms/Button')

const DEFAULTS = {
  PRODUCT_ID: 110610,
  CAMPAIGN_ID: 1,
  CATEGORY_ID: 1,
  CMS_PAGE_ID: 3891
}
export default {
  name: 'TheOverviewPage',
  mixins: [pageMixin],
  components: { Button, TheFooter, Header },
  computed: {
    pdpUrl () {
      return `-pdp-p${DEFAULTS.PRODUCT_ID}-c${DEFAULTS.CAMPAIGN_ID}`
    },
    categoryUrl () {
      return `-cat-c${DEFAULTS.CATEGORY_ID}`
    },
    cmsUrl () {
      return `-cms-p${DEFAULTS.CMS_PAGE_ID}`
    },
    opcUrl () {
      return `-opc-p${DEFAULTS.PRODUCT_ID}-c${DEFAULTS.CAMPAIGN_ID}`
    },
    title () {
      return 'Overview'
    },
    meta () {
      return {
        title: this.title,
        description: this.translate('fe_app_front_page_desc')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  min-height: 300px;
}
</style>
