<template>
  <div v-show="readyToRender" id="content" data-elbglobals="page_type:cart">
    <AppBanner/>
    <TheHeader/>
    <TheCartTemplate :show-modal="showModal" @setupCheckout="val => this.canCheckout = val"/>
    <LazyComponent id="recent-items"  v-if="customerProfile.customer_id" :delay="500">
      <TheRecentlyViewedItems class="pt-5"/>
    </LazyComponent>
    <LazyComponent id="footer">
      <TheFooter/>
    </LazyComponent>
    <MultiTools id="fixed-tools" v-if="customerProfile.is_admin" />
    <TheCartMobileCTA v-if="showMobileCTA" @showModal="showModal"/>
  </div>
</template>

<script>
import { TYPES } from '@/constants/AdminTool'
import pageMixin from '@/components/basic/mixins/pageMixin'
import TheCartTemplate from '@/components/cart/templates/TheCartTemplate'
import modalMixin from '@/components/basic/mixins/modalMixin'
import { mapState } from 'pinia'
import { useCustomerStore } from '@/stores/customer'

const LazyComponent = () => import('@/components/basic/atoms/LazyComponent.vue')
const TheHeader = () => import('@/components/basic/organisms/TheHeader')
const AppBanner = () => import('@/components/basic/molecules/AppBanner')
const TheCartMobileCTA = () => import('@/components/cart/molecules/TheCartMobileCTA')
const MultiTools = () => import('@/components/basic/molecules/MultiTools')
const TheRecentlyViewedItems = () => import('@/components/basic/molecules/TheRecentlyViewedItems')
const TheFooter = () => import('@/components/basic/organisms/TheFooter')

export default {
  name: 'TheCartPage',
  mixins: [pageMixin, modalMixin],
  components: {
    LazyComponent,
    TheCartTemplate,
    TheCartMobileCTA,
    MultiTools,
    TheHeader,
    TheRecentlyViewedItems,
    TheFooter,
    AppBanner
  },
  data () {
    return {
      adminTool: TYPES,
      canCheckout: false
    }
  },
  computed: {
    ...mapState(useCustomerStore, ['customerProfile']),
    title () {
      return this.translate('new_cart_page_cart')
    },
    meta () {
      return {
        title: this.title,
        description: this.translate('fallback_meta_description'),
        robots: 'noindex, nofollow'
      }
    },
    showMobileCTA () {
      return this.canCheckout && (this.$root.gridBreakpoint < this.$gridSizes.lg)
    }
  }
}
</script>

<style scoped lang="scss">
.sidebar > div {
  top: 0;
}
</style>
