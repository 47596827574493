<template>
  <div  id="content" data-elbglobals="page_type:category" data-elbcontext="shopping:discover">
    <AppBanner/>
    <AlertBanner v-if="!pageIsVisible" :text="translate('alert_message_preview_mode')"/>
    <TheHeader/>
    <main id="page-template">
      <TheCategoryTemplate/>
    </main>
    <LazyComponent id="footer" :delay="2000">
      <Footer/>
    </LazyComponent>
  </div>
</template>

<script>
import pageMixin from '@/components/basic/mixins/pageMixin'
import TheHeader from '@/components/basic/organisms/TheHeader'
import TheCategoryTemplate from '@/components/category/templates/TheCategoryTemplate'
import { mapActions, mapState } from 'pinia'
import { useSearchConfig } from '@/stores/searchConfig'

const LazyComponent = () => import('@/components/basic/atoms/LazyComponent.vue')
const AppBanner = () => import('@/components/basic/molecules/AppBanner')
const AlertBanner = () => import('@/components/basic/atoms/AlertBanner')
const Footer = () => import('@/components/basic/organisms/TheFooter')

const NAME = 'TheCategoryPage'

export default {
  name: NAME,
  mixins: [pageMixin],
  components: {
    AppBanner,
    AlertBanner,
    TheHeader,
    TheCategoryTemplate,
    LazyComponent,
    Footer
  },
  computed: {
    ...mapState(useSearchConfig, ['category', 'getConfig']),
    footer () {
      return Footer
    },
    metaOnCreate () {
      return false
    },
    title () {
      return this.category.meta_title || this.category.name || ''
    },
    meta () {
      return {
        title: this.title,
        description: this.category.meta_description,
        robots: this.category.robots
      }
    },
    pageIsVisible () {
      return this.category.is_enabled
    }
  },
  methods: {
    ...mapActions(useSearchConfig, ['fetchCategory']),
  },
  beforeRouteEnter(_t, _f, next) {
    next((vm) => {
      vm.fetchCategory()
    })
  }
}
</script>
