<template>
  <div id="content">
    <main id="checkout-template">
      <TheCheckoutTemplate :step="currentStep" />
    </main>
    <LazyComponent id="footer" :delay="2000">
      <Footer />
    </LazyComponent>
  </div>
</template>

<script>
import TheCheckoutTemplate from '@/components/checkout/template/TheCheckoutTemplate.vue'

const LazyComponent = () => import('@/components/basic/atoms/LazyComponent.vue')
const Footer = () => import('@/components/basic/organisms/TheFooter.vue')

export default {
  name: 'TheCheckoutPage',
  components: {
    TheCheckoutTemplate,
    LazyComponent,
    Footer
  },
  computed: {
    currentStep() {
      return this.$route.params.step
    }
  }
}
</script>
